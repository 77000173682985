<template>
    <v-skeleton-loader
      v-if="isLoading"
      type="card, actions"
      elevation="2"
    ></v-skeleton-loader>
    <template v-else>
      <v-stepper 
        :mobile="isMobile" 
        v-model="step" 
        alt-labels 
        flat 
        v-if="step < 5">
        <v-stepper-header>
          <v-stepper-item
            title="Detalle de producto"
            color="primary"
            :value="1"
            :complete="step > 1"
            alt
            icon="none"
            :complete-icon="'mdi-check'"
          ></v-stepper-item>
            
          <v-divider></v-divider>
            
          <v-stepper-item
            :complete-icon="'mdi-check'" 
            title="Datos de compra"
            color="primary"
            :value="2"
            :complete="step > 2"
            alt
            icon="none"
          ></v-stepper-item>
            
          <v-divider></v-divider>
          
          <v-stepper-item
            title="Confirmación y pago"
            color="primary"
            :value="3"
            :complete="step > 3"
            alt
            icon="none"
          ></v-stepper-item>
          
          <v-divider></v-divider>
    
          <v-stepper-item
            title="Generar informe"
            color="primary"
            :value="4"
            icon="none"
          ></v-stepper-item>
    
        </v-stepper-header>
    
        <v-window v-model="step" transition="fade-transition">
          <v-window-item :value="1">
            <v-container>
              <v-row justify="center">
                <v-col cols="12" md="8">
                  <Step1 />
                </v-col> 
                <v-col cols="12" md="4">
                  <purchase-order />
                </v-col>
              </v-row>
            </v-container>
          </v-window-item>

          <v-window-item :value="2">
            <v-container>
              <v-row justify="center">
                <v-col cols="12" md="8">
                  <Step2 />
                </v-col> 
                <v-col cols="12" md="4">
                  <purchase-order />
                </v-col>
              </v-row>
            </v-container>
          </v-window-item>

          <v-window-item :value="3">
            <v-container>
              <v-row justify="center">
                <v-col cols="12" md="8">
                  <Step3 />
                </v-col> 
                <v-col cols="12" md="4">
                  <purchase-order />
                </v-col>
              </v-row>
            </v-container>
          </v-window-item>

          <v-window-item :value="4">
            <v-container>
              <v-row justify="center">
                <v-col cols="12" md="8">
                  <Step4 />
                </v-col> 
              </v-row>
            </v-container>
          </v-window-item>
        </v-window>
      </v-stepper>
      <Complete v-else />
    </template>
</template>
  
<script>
import api from '@/api';
import { PurchaseOrder } from '../components';
import {Complete, Step1, Step2, Step3, Step4} from '../views';
import { mapGetters } from 'vuex';

  export default {
    name: 'PurchaseEvent',
    components:{
      Complete,
      Step1,
      Step2,
      Step3,
      Step4,
      PurchaseOrder
    },
    data(){
      return{
        isMobile: true,
        isLoading: true,
      }
    },
    computed: {
      ...mapGetters('cart', ['step'])
    },
    methods: {
      updateItemInCart(itemId) {
        this.$store.dispatch('cart/addItem', itemId);
      },
      onResize() {
        if (window.innerWidth <= 1270) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
      },
    },
    async created() {
      const itemId = this.$route.params.id;
      const itemToken = this.$route.params.token;
      const isFormStarted = this.step > 1;
      try {
        if(isFormStarted) return;
        if (itemId) {
          if (itemToken) {
            const { data } = await api.validateOrder(itemToken);
            
            if(data.length === 0){
              this.$store.dispatch('cart/flowComplete');
            }else{
              this.updateItemInCart(itemId);
              this.$store.dispatch('cart/backFlow', data.cant_informes);
              this.$store.dispatch('payment/updOrdenData', data.orden );
            }
          }
          else{
            this.updateItemInCart(itemId);
          }
        }else{
          this.$store.dispatch('cart/clearCart');
          this.$store.dispatch('payment/resetCheckout');
        }
      } catch (error) {
        console.error("Ha ocurrido un error:", error);
        this.$store.dispatch('snackbar/showSnack', { text: 'El token es inválido', type: 'error' });
        this.$router.push('/compra-informes');

      }finally{
        this.isLoading = false;
      }
    },
    mounted() {
      window.addEventListener('resize', this.onResize);
      this.onResize(); 
    },
  }
</script>

<style lang="scss" scoped>
.v-stepper-header{
   box-shadow: none !important;
  ::v-deep .v-stepper-item__title{
    font-size: 14px;
    white-space: nowrap !important; 
  }
}
::v-deep .v-stepper-item.v-stepper-item--complete {
  .v-avatar.v-theme--light.bg-primary.v-avatar--density-default.v-avatar--variant-flat.v-stepper-item__avatar {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
    color: $purple-700 !important;
    i{
      font-size: 1.6rem;
    }
  }
}
</style>